import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import Layout from '../../components/Layout'



class PoliticaCookies extends React.Component {

    render() {

        return (
            <Layout title={"Política de cookies | Modelizame"} description={"Política de cookies"}>
                
                <section className="section margin-top-navbar" style={{minHeight: '80vh'}}>
                    <div className="container">
                        <h4 className="title is-4">
                            Política de cookies
                        </h4>
                        <p className='mb-40'>
                            Debido a la entrada en vigor de la referente modificación de la "Ley de Servicios de la Sociedad de la Información" (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento expreso del usuario de todas las páginas web que usan Cookies prescindibles, antes de que éste navegue por ellas.
                        </p>
                        <h4 className="title is-4">
                            ¿Qué son las Cookies?
                        </h4>
                        <p className='mb-40' >
                        Las Cookies y otras tecnologías similares tales como local shared objects, flash Cookies o píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus visitantes, así como para ofrecer un correcto funcionamiento del sitio. Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña, productos que más le interesan, etc.
                        </p>
                        
                            
                        <h4 className="title is-4">
                        Cookies afectadas por la normativa y Cookies exceptuadas
                        </h4>
                        <p className='mb-40'>
                            Según la directiva de la UE, las Cookies que requieren el consentimiento informado por parte del usuario son las Cookies de analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio Web o la prestación de servicios expresamente solicitados por el usuario.
                        </p>

                        <h4 className="title is-4">
                            ¿Qué tipos de Cookies existen?
                        </h4>
                        <p>
                            Sobre los tipos de Cookies, existen cinco grandes grupos:
                        </p>
                        <ul className='mb-40' style={{listStyleType:'disc', paddingLeft:'40px'}}>
                            <li>
                                Cookies analíticas: recogen información del uso que se realiza del sitio Web.
                            </li>
                            <li>
                                Cookies sociales: son aquellas necesarias para redes sociales externas.
                            </li>
                            <li>
                                Cookies de publicidad y comportamentales: recogen información sobre las preferencias y elecciones personales del usuario (retargeting).
                            </li>
                            <li>
                                Cookies técnicas y funcionales: son las estrictamente necesarias para el uso del sitio Web y para la prestación del servicio contratado.
                            </li>
                        </ul>

                        <h4 className="title is-4">
                            Cookies que se utilizan en este sitio Web
                        </h4>
                        <ul className='mb-40' style={{listStyleType:'disc', paddingLeft:'40px'}}>
                            <li>
                                _gat, _gid, _ga: Cookies de Google Analytics que habilita la función de control de visitas únicas. 
                                La primera vez que un usuario entre en el sitio Web a través de un navegador se instalará esta Cookie. 
                                Cuando este usuario vuelva a entrar en la web con el mismo navegador, la Cookie considerará que es el mismo usuario. 
                                Solo en el caso de que el usuario cambie de navegador, se considerará otro usuario. Caduca a los 2 años desde la última actualización. 
                                En <a href="https://policies.google.com/privacy?hl=es&gl=es" target="_blank" rel="nofollow noopener noreferrer">https://policies.google.com/privacy?hl=es&gl=es</a> encontrarás más información sobre la Política de Privacidad de Google.
                            </li>
                        </ul>


                        <h4 className="title is-4">
                            ¿Cómo puedo configurar las Cookies o deshabilitarlas?
                        </h4>

                        <p>
                            Puede aceptar, bloquear o eliminar las Cookies instaladas en su equipo mediante la configuración de las opciones del navegador. En los siguientes enlaces encontrará instrucciones para habilitar o deshabilitar las Cookies en los navegadores más comunes:
                        </p>

                        <ul className='mb-40' style={{listStyleType:'disc', paddingLeft:'40px'}}>
                            <li>
                                Google Chrome <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="nofollow noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=es</a>
                            </li>
                            <li>
                                Firefox <a href="https://support.mozilla.org/es/kb/proteccion-de-rastreo-mejorada-en-firefox-para-esc" target="_blank" rel="nofollow noopener noreferrer">https://support.mozilla.org/es/kb/proteccion-de-rastreo-mejorada-en-firefox-para-esc</a>
                            </li>
                            <li>
                                Safari <a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="nofollow noopener noreferrer">https://support.apple.com/es-es/HT201265</a>
                            </li>
                            <li>
                                Otros navegadores: Consulte la documentación del navegador que tenga instalado.
                            </li>
                        </ul>

                        <h4 className="title is-4">
                            Identidad del responsable
                        </h4>
                        <p className='mb-40'>
                            Modelizame S.L. (en constitución)
                        </p>


                    </div>
                        
                </section>

            </Layout>
        )
    }
}

export default PoliticaCookies